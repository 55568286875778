ul.nav > li.nav-item > span.active {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #f8f9fa !important;
}
ul.nav > li.nav-item > span.nav-link {
  cursor: pointer;
}

$navWidth: 252px;
$navCollapsedWidth: 90px;

.nav-container {
  width: $navWidth;
  min-width: $navWidth;
}

.nav-container.nav-collapsed {
  width: $navCollapsedWidth !important;
  min-width: $navCollapsedWidth !important;

  .nav-side-menu {
    width: calc(#{$navCollapsedWidth} - 32px) !important;
    min-width: calc(#{$navCollapsedWidth} - 32px) !important;
  }
  .nav-side-menu li {
    text-align: center;
    padding-left: 0px;
    border-left: 0;
    border-bottom: 1px solid #23282e;
  }
  li.active {
    border-left: 3px solid #d19b3d;
  }
  .sub-menu > li {
    border-left: 0;
  }
}

.nav-side-menu {
  overflow: hidden;
  font-size: 14px;
  font-weight: 200;
  top: 0px;
  height: 100%;
  color: #efefef;
  width: 220px;
  min-width: 220px;
}

.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
  padding-bottom: 20px;
}
.nav-side-menu .menu-content {
  background-color: #2e353d;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f078";
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: #d19b3d !important;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: #d19b3d !important;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #020203;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  padding-left: 10px;
}
.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #efefef;
}
.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li:hover {
  border-left: 3px solid #f44242;
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.nav-side-menu .menu-list .menu-content {
  display: block;
}
#main {
  width: calc(100% - 300px);
  float: right;
}
