$theme-colors: (
  "secondary-light": #e2e2e2
);

@import "~bootstrap/scss/bootstrap";
@import "./dark.scss";

.react-datepicker-wrapper {
  flex-grow: 1;
  width: auto !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #191c1f;
}

.custom-control-input ~ .custom-control-label::before {
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-input:checked:disabled ~ .custom-control-label::before {
  background-color: #343a407e !important;
  border-color: #191c1f7e !important;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #ffffff7e !important;
  border: #adb5bd7e solid 1px !important;
}
.custom-control-input:disabled ~ .custom-control-label::after {
  background-color: #ffffff7e !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #00bd10 !important;
}
.custom-control-input:checked:disabled ~ .custom-control-label::after {
  background-color: #00bd107e !important;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.pagination {
  justify-content: flex-end;
}

.tablelink:hover {
  color: #0b55a2;
}

.tablelink a {
  color: #0b55a2;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
  color: #0b55a2;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
  color: #0b55a2;
  text-shadow: 2px 4px 3px #0b54a23b;
}

.bgNormalized {
  background-color: #ffffff;
}

.bg-dark .display-6 {
  color: white;
}

.btn-group {
  z-index: 0;
}
.portalcontent {
  background: linear-gradient(white, #e0e0e0);
}

.sticky {
  position: sticky;
  width: 300px;
  top: 0;
}

.sticky:before,
.sticky:after {
  content: "";
  display: table;
}

body {
  min-height: 100vh;
  height: 100%;
}

main {
  flex: 1;
  min-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-croshair {
  cursor: crosshair;
}
.cursor-eresize {
  cursor: e-resize;
}
.cursor-move {
  cursor: move;
}

.card-news-body img {
  padding: 10px;
}

.highlight-search {
  background-color: rgba(255, 255, 0, 0.6);
  margin: 0;
  padding: 0;
}

.transparent-tippy {
  background-color: transparent !important;
}

.tippy-popper {
}

.protocol-pre {
  max-height: 70vh;
  overflow: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  pre {
    overflow: visible;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #aaa; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(77, 77, 77);
  }
}

.react-json-view-container {
  max-width: 60vw;
  .react-json-view {
    background-color: transparent !important;
    font-size: 8pt;
    padding: 10px;
    .variable-row {
      padding: 0px !important;
    }
  }
}


.selectBoxOuterContainer {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin-top: 5px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.selectBoxInnerContainer {
  position: relative;
  margin: 5px 0px 5px 0;
  padding: 5px 30px 5px 5px;
  max-width: 100%;
  border-radius: 3px;
  background-color: #fafafa;
  border: 1px solid #ced4da;
  line-height: 13px;
  cursor: default;
  box-sizing: "border-box";
  width: 100%;
  font-size: small;
}

.you-are-no-button {
  border: 0;
  background: inherit;
  padding: 0;
  margin: 0;
}

.nav-link {
  color: #0b55a2 !important
}



/** SLIDER **/

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0px;
  
}

.switch.small {
  height: 17px;
  width: 30px;
}

.switch.medium {
  height: 23px;
  width: 40px;
}


.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;

}

.disabled .slider:before {
  background-color: #dfdfdf;
}

.small .slider:before {
  height: 13px;
  width: 13px;
  left: 2px;
  top: 2px;
}

.medium .slider:before {
  height: 17px;
  width: 17px;
  left: 2px;
  top: 3px;
}

input:checked + .slider {
  background-color: #2196F3;
}

.disabled input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.small input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.medium input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-text {
  margin-left: 20px;
}

.switch-text.small  {
  margin-left: 10px;
}







.mailtemplate {
  img {
    border: none;
    -ms-interpolation-mode: bicubic;
    max-width: 100%; }
  body {
    background-color: #23282e;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  table {
    border-collapse: separate;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    width: 100%; }
    table td {
      font-family: sans-serif;
      font-size: 14px;
      vertical-align: middle; }
  
  .container {
    background-color: #23282e;
    width: 100%; 
    display: block;
    Margin: 0 auto !important;
    /* makes it centered */
    max-width: 580px;
    padding: 0px;
     }
  /* This should also be a block element, so that it will fill 100% of the .container */
  .content {
    box-sizing: border-box;
    display: block;
    Margin: 0 auto;
    max-width: 580px;
    padding: 10px; }
  /* -------------------------------------
      HEADER, FOOTER, MAIN
  ------------------------------------- */
  .main {
    background: #ffffff;
    border-radius: 3px;
    width: 100%; }
  .wrapper {
    box-sizing: border-box;
    padding: 20px; }
  .content-block {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .footer, .header {
    clear: both;
    Margin-top: 10px;
    text-align: center;
    width: 100%; }
    .footer td,
    .footer p,
    .footer span,
    .footer a {
      color: #999999;
      font-size: 12px;
      text-align: center; }
  /* -------------------------------------
      TYPOGRAPHY
  ------------------------------------- */
  h1,
  h2,
  h3,
  h4 {
    color: #000000;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
    Margin-bottom: 30px; }
  h1 {
    font-size: 35px;
    font-weight: 300;
    text-align: center;
    text-transform: capitalize; }
  p,
  ul,
  ol {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    Margin-bottom: 15px; }
    p li,
    ul li,
    ol li {
      list-style-position: inside;
      margin-left: 5px; }
  a {
    color: #3498db;
    text-decoration: underline; }
  
  /* -------------------------------------
      BUTTONS
  ------------------------------------- */
  .btn {
    box-sizing: border-box;
    width: 100%; }
    .btn > tbody > tr > td {
      padding-bottom: 15px; }
    .btn table {
      width: auto; }
    .btn table td {
      background-color: #ffffff;
      border-radius: 5px;
      text-align: center; }
    .btn a {
      background-color: #ffffff;
      border: solid 1px #3498db;
      border-radius: 5px;
      box-sizing: border-box;
      color: #3498db;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 12px 25px;
      text-decoration: none;
      text-transform: capitalize; }
  .btn-primary table td {
    background-color: #3498db; }
  .btn-primary a {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff; }
  /* -------------------------------------
      OTHER STYLES THAT MIGHT BE USEFUL
  ------------------------------------- */
  .last {
    margin-bottom: 0; }
  .first {
    margin-top: 0; }
  .align-center {
    text-align: center; }
  .align-right {
    text-align: right; }
  .align-left {
    text-align: left; }
  .clear {
    clear: both; }
  .mt0 {
    margin-top: 0; }
  .mb0 {
    margin-bottom: 0; }
  .preheader {
    color: transparent;
    display: none;
    height: 0;
    max-height: 0;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    mso-hide: all;
    visibility: hidden;
    width: 0; }
  .powered-by a {
    text-decoration: none; }
  hr {
    border: 0;
    border-bottom: 1px solid #f6f6f6;
    Margin: 20px 0; }
}