.bg-dark {
  input,
  textarea,
  select,
  .actionCreatedByInnerContainer,
  .actionCreatedByOuterContainer,
  .input-group-text {
    background-color: #40454b;
    color: #d0d5da !important;
    border: 1px solid #52585f;
  }

  .border {
    border: 1px solid #747679 !important;
  }

  .border-left {
    border-left: 1px solid #747679 !important;
  }

  .border-right {
    border-right: 1px solid #747679 !important;
  }
  
  .border-top {
    border-top: 1px solid #747679 !important;
  }  

  .border-bottom {
    border-bottom: 1px solid #747679 !important;
  }

  .ql-formats {
    
    background: #ffffff;
  }

  .ql-toolbar {
    
    background: #40454b;
  }

  a {
    color: #98b4d6;
  }

  .btn-outline-secondary {
    color: rgb(231, 231, 231) !important;
  }

  .page-link {
    background-color: #40454b !important;
    border: 1px solid #52585f !important;
  }

  .page-item.disabled {
    .page-link {
      color: #6c757d !important;
    }
  }

  .page-item {
    .page-link {
      color: #c1cbd3 !important;
    }
  }

  .page-item.active {
    .page-link {
      color: white !important;
    }
  }

  .tablelink:hover {
    color: #c7c7c7;
  }

  .tablelink a {
    color: #ffffff;
  }

  ul.nav > li.nav-item > span.active {
    background-color: #454d55 !important;
    color: white;
    border-color: #6c757d !important;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #d0d5da;
    background-color: #343a40;
    border-color: #747679 #747679 #343a40;
  }

  .nav-link {
    color: white !important;
  }

  .nav-link:hover {
    border-bottom-color: #65696d;
  }

  .nav-link.active:hover {
    color: lighten(#495057, 10) !important;
  }

  .form-control:focus {
    color: #d0d5da !important;
    background-color: #40454b;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.list-dark {
  background-color: #454d55;
  color: #ffffff;
}

.list-dark:hover {
  background-color: #555e68;
  color: #ffffff;
}

